define(['app'], (app) => {

  const productAddToCollection = () => {
    const component = {};

    const _config = {
      selectors: {
        options: '[data-list-type]',
        toggle: '.productAddToCollection_dropdown_toggle',
        toggleIcon: '.productAddToCollection_dropdown_toggle .icon',
        dropdownContainer: '.productAddToCollection_dropdown_container',
      },
      attributes: {
        dataListType: 'data-list-type',
        dataProductId: 'data-product-id',
      },
      classNames: {
        dropdownContainerHide: 'productAddToCollection_dropdown_container-hide',
      }
    }

    const _init = (element) => {
      component.element = element;

      if(element) {
        component.children = {
          toggleIcon: element.querySelector(component.config.selectors.toggleIcon),
          toggle: element.querySelector(component.config.selectors.toggle),
          dropdownContainer: element.querySelector(component.config.selectors.dropdownContainer),
          options: element.querySelectorAll(component.config.selectors.options),
        };

        component.attrs = {
          productId: element.getAttribute(component.config.attributes.dataProductId),
        };
      }

      component.addListeners();

      return component;
    };

    const _showDropdown = () => {
      component.children.dropdownContainer.classList.remove(component.config.classNames.dropdownContainerHide);
      component.children.toggle.setAttribute('aria-expanded', 'true');
    }

    const _hideDropdown = () => {
      component.children.dropdownContainer.classList.add(component.config.classNames.dropdownContainerHide);
      component.children.toggle.setAttribute('aria-expanded', 'false');
    }

    const _toggleOnClickHandler = () => {
      (component.children.toggle.getAttribute('aria-expanded') === 'true') ? component.hideDropdown() : component.showDropdown();
    };

    const _dropdownOnLostFocusHandler = event => {
      if (!component.element.contains(event.relatedTarget)) {
        component.hideDropdown();
      }
    };

    const _keyDownEventHandler = event => {
      if ((event.key === 'ESC' || event.key === 'Escape')) {
        component.children.toggle.focus();
        component.hideDropdown();
      }
    }

    const _addListeners = () => {
      for(let option of component.children.options) {
        option.addEventListener('click', component.optionOnClick.bind(null, option));
      }
      component.children.toggle.addEventListener('click', component.toggleOnClickHandler);
      component.element.addEventListener('focusout', component.dropdownOnLostFocusHandler);
      component.children.dropdownContainer.addEventListener('keydown', component.keyDownEventHandler);
    };

    const _optionOnClick = (option) => {
      const listType = option.getAttribute(component.config.attributes.dataListType);
      const selected = option.getAttribute('data-selected');
      if (selected === 'true') {
        component.children.toggleIcon.className = 'icon neutral-icon';
        return component.removeFromList(listType)
          .then(component.fetchDom)
          .then(component.replaceDom)
          .catch(console.error);
      } else {
        component.children.toggleIcon.className = `icon ${listType}-icon`;
        return component.addToList(listType)
          .then(component.fetchDom)
          .then(component.replaceDom)
          .catch(console.error);
      }
    };

    const _fetchDom = () => {
      return new Promise((res, rej) => app.ajax.get({
        url: `/${component.attrs.productId}.addToCollection`,
        success: res,
        error: rej,
      }));
    };

    const _replaceDom = (response) => {
      const parent = component.element.parentNode;
      parent.innerHTML = response;
      const newElement = parent.querySelector('[data-component=productAddToCollection]');
      const newComponent = new productAddToCollection();
      newComponent.init(newElement);
      newComponent.children.toggle.focus();
    };

    const _addToList = (type) => {
      let mapCategoriesToSkus = 'false';
      let endpoint = `/${type}/${mapCategoriesToSkus}/addTo.collections`;

      return new Promise((res, rej) => app.ajax.post({
        url: endpoint,
        send: [component.attrs.productId],
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json'
        },
        success: res,
        error: rej
      }));
    };

    const _removeFromList = (type) => {
      return new Promise((res, rej) => app.ajax.call({
        url: `/${type}/removeFrom.collections`,
        send: [component.attrs.productId],
        type: 'DELETE',
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json'
        },
        success: res,
        error: rej
      }));
    };

    component.config = _config;
    component.init = _init;
    component.addListeners = _addListeners;
    component.optionOnClick = _optionOnClick;
    component.fetchDom = _fetchDom;
    component.replaceDom = _replaceDom;
    component.addToList = _addToList;
    component.removeFromList = _removeFromList;
    component.showDropdown = _showDropdown;
    component.hideDropdown = _hideDropdown;
    component.toggleOnClickHandler = _toggleOnClickHandler;
    component.dropdownOnLostFocusHandler = _dropdownOnLostFocusHandler;
    component.keyDownEventHandler = _keyDownEventHandler;

    return component;
  };

  return productAddToCollection;
});
